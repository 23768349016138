import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './auth.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { MobileOrientationComponent } from './common/components/mobile-orientation/mobile-orientation.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { NotificationListComponent, DateAgoPipe } from './common/components/notification-list/notification-list.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { GlobalfeedbackComponent } from './common/components/globalfeedback/globalfeedback.component';
import { PreloadImageComponent } from './common/components/preload-image/preload-image.component';
import { ComponentsModule } from './components/components.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { UserRegisteredPageModule } from './admin/pages/user-registered/user-registered.module';
import { VideoModelPage } from './common/pages/video-model/video-model.page';
import { MeetingSchedulerPage } from './common/pages/meeting-scheduler/meeting-scheduler.page';
import { MessageModelPage } from './common/pages/message-model/message-model.page';
import { FeedbackPage } from './common/pages/feedback/feedback.page';
import { EnquiryPage } from './common/pages/enquiry/enquiry.page';
import { SliderComponent } from './common/components/slider/slider.component';
import { FilePage } from './file/pages/file.page';
import { OtpPage } from './otp/pages/otp/otp.page';
import { VideoModelPageModule } from './common/pages/video-model/video-model.module';
import { OtpPageModule } from './otp/pages/otp/otp.module';
import { MessageModelPageModule } from './common/pages/message-model/message-model.module';
import { FilePageModule } from './file/pages/file.module';
import { FeedbackPageModule } from './common/pages/feedback/feedback.module';
import { EnquiryPageModule } from './common/pages/enquiry/enquiry.module';
import { MeetingSchedulerPageModule } from './common/pages/meeting-scheduler/meeting-scheduler.module';
import { LoginTwoPage } from './login/pages/login-two/login-two.page';
import { LoginTwoPageModule } from './login/pages/login-two/login-two.module';
import { UserPageModule } from './admin/pages/users/user/user.module';
import { UserPage } from './admin/pages/users/user/user.page';
import { AssociationsLobbyPageModule } from './associations/pages/associations-lobby/associations-lobby.module';
import { AssociationsListPageModule } from './associations/pages/associations-list/associations-list.module';
import { CountriesListPageModule } from './countries/pages/countries-list/countries-list.module';
import { CountriesLobbyPageModule } from './countries/pages/countries-lobby/countries-lobby.module';
import { AssociationsListPage } from './associations/pages/associations-list/associations-list.page';
import { AssociationsLobbyPage } from './associations/pages/associations-lobby/associations-lobby.page';
import { CountriesListPage } from './countries/pages/countries-list/countries-list.page';
import { CountriesLobbyPage } from './countries/pages/countries-lobby/countries-lobby.page';
import { ColorpickerPage } from './common/components/colorpicker/colorpicker.page';
import { ColorpickerPageModule } from './common/components/colorpicker/colorpicker.module';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, AuthService } from 'angular-6-social-login';

export function socialConfigs() {
  const config = new AuthServiceConfig(
    [
      {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider('111552227702308')
      },
      {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('58115043328-knal4enkeqp62prcgu4kjm5vfn1ncl65.apps.googleusercontent.com')
      }
    ]
  );
  return config;
}

@NgModule({
  declarations: [AppComponent, MobileOrientationComponent, DateAgoPipe,
    GlobalfeedbackComponent, PreloadImageComponent],


  entryComponents: [NotificationListComponent,
    VideoModelPage,
    OtpPage,
    MessageModelPage,
    FilePage,
    FeedbackPage,
    EnquiryPage,
    MeetingSchedulerPage,
    SliderComponent,
    LoginTwoPage,
    UserPage,
    ColorpickerPage,
    AssociationsListPage,
    AssociationsLobbyPage,
    CountriesListPage,
    CountriesLobbyPage,
  ],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, BrowserAnimationsModule,
    HttpClientModule, FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    ComponentsModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    AuthGuard,
    ScreenOrientation,
    AngularFireAuthModule,
    UserRegisteredPageModule,
    AngularFireAuth,
    AngularFireDatabase,
    AuthService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: AuthServiceConfig, useFactory: socialConfigs }
  ],
  exports: [
    VideoModelPageModule,
    OtpPageModule,
    MessageModelPageModule,
    FilePageModule,
    FeedbackPageModule,
    MeetingSchedulerPageModule,
    EnquiryPageModule,
    LoginTwoPageModule,
    AssociationsListPageModule,
    AssociationsLobbyPageModule,
    UserPageModule,
    ColorpickerPageModule,
    CountriesListPageModule,
    CountriesLobbyPageModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../base.service';


@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }
  registerUser(data) {
    return super.post<any>('register', true, data);
  }

  socialLogin(data) {
    return super.post<any>('social-login', true, data);
  }
}

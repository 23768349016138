import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { CountryService } from '../../services/country.service';

@Component({
  selector: 'app-countries-list',
  templateUrl: './countries-list.page.html',
  styleUrls: ['./countries-list.page.scss'],
})
export class CountriesListPage implements OnInit {

  countryList: any = [];
  event: any = [];

  constructor(public countryService: CountryService, public loadingService: LoadingService,
    public router: Router, public toastService: ToastService,
    public pageService: PageService) { }

  ngOnInit() {
    this.event = this.pageService.getValue('event');
    this.initData();
  }

  initData() {
    this.loadingService.present();
    this.countryService.getAllCountries(this.event.id).subscribe(value => {
      this.countryList = value.data;
      for (let index in this.countryList) {
        this.countryList[index].imgurl = 'https://expoassets.ams3.digitaloceanspaces.com/flags/' + this.countryList[index].id + '.png';
      }
      this.countryList = this.countryList.sort((a, b) => b.count - a.count);
      this.loadingService.dismiss();
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  goToPage(data) {
    this.pageService.storeValue({ id: data.id, name: data.name, logo_url: data.imgurl }, 'country-category');
    this.router.navigate(['countries-lobby']);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordService } from 'src/app/forgot-password/services/forgot-password.service';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { OtpPage } from 'src/app/otp/pages/otp/otp.page';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.page.html',
  styleUrls: ['./forget-password.page.scss'],
})
export class ForgetPasswordPage implements OnInit {

  forgotPassForm: FormGroup;
  public static pageId: number = 5;
  pageData: any = [];
  logo: any;

  backgroundImage: string = environment.bannerDefault;

  constructor(private formBuilder: FormBuilder, public forgotPasswordService: ForgotPasswordService,
              public loadingService: LoadingService, public pageService: PageService, 
              public toastService: ToastService, public modalController: ModalController,
              public router: Router) {

    this.forgotPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

   }

   ngOnInit() {
    this.getInitData();

    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });
  }

  getInitData() {
    this.pageService.storePageData(ForgetPasswordPage.pageId).then(value => {
      this.pageData = value;  
      if(this.pageData.page_component != null)
      {
        this.pageData.page_component.forEach(element => {
            if(element.paComponentType == "image")
            {  
              if(element.image.identifier == 'bg-image'){
                this.backgroundImage = element.image.atta_url;
              }  
            } 
        }); 
      }        
    }); 
  }


  onChangePasswordClicked() {
    this.loadingService.present();
    this.forgotPasswordService.forgotPassword(this.forgotPassForm.value).subscribe(value => {
      this.loadingService.dismiss();
      this.showOTPModal(value.data);
      this.toastService.presentToast({ message: value.message, status: 'default' });
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  async showOTPModal(data)
  {
    const modal = await this.modalController.create({
      component: OtpPage,
      cssClass: 'msgpop bigspo',
      backdropDismiss:false,
      componentProps: {
        'messageData': 'Your pre-registered login ID is not registered as visitor for this current show. Please proceed here to register!',
        'userId': data.id,
        'userEmail': data.user_email.substring(0, 4)
      }
    });
    modal.onDidDismiss().then((result) => {
      this.router.navigate(['reset-password/'+data.id]);
    });
    return await modal.present();
  }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonInfiniteScroll, PopoverController } from '@ionic/angular';
import * as firebase from 'firebase';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { ChatService } from '../../service/chat.service';
import { PopoverComponent } from '../popover/popover.component';

@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss'],
})
export class ChatboxComponent implements OnInit {

  chats: any = [];
  message: string;
  currentUser: any = {};
  userDetails: any = [];
  messageCount: number = 0;
  replyTo: any = '';
  replyToMessage: string = '';
  replyToName: string = '';
  showBtmReply: boolean = false;
  userRoles: any = [];
  @ViewChild(IonContent) contentArea: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Input() seminarId: any = [];
  ref = firebase.database().ref("groupchat");

  constructor(public chatService: ChatService, public popoverController: PopoverController,
    public pageService: PageService, public authHelperService: AuthHelperService) {
    this.userRoles = this.authHelperService.getRoles();
    this.messageCount = 100;
    this.pageService.getUserProfile().then(result => {
      this.currentUser = result;
    });
  }

  ngOnInit() {
    this.ref.child(this.seminarId).limitToLast(this.messageCount).on('value', (snapshot) => {
      var obj = snapshot.val();
      let j = 0;
      this.chats = [];
      for (var key in obj) {
        if (obj[key]['replyTo'] != null && obj[key]['replyTo'] != '') {
          this.ref.child(this.seminarId).child(obj[key]['replyTo']).once('value', (snap) => {
            var replyVal = snap.val();
            obj[key]['parentMsg'] = replyVal.message;
            obj[key]['parentUser'] = replyVal.userId;
          });
        }
        obj[key]['key'] = key;
        this.chats[j++] = obj[key];
      }
      this.scrollToBottom();
    });
  }

  loadMessages(event) {
    this.messageCount += 2;
    this.ref.child(this.seminarId).limitToLast(this.messageCount).once('value', (snapshot) => {
      console.log(this.messageCount, snapshot.val());
    });
  }

  sendMessage() {
    if (this.message != null && this.message != "" && this.message != '\n')
      this.ref.child(this.seminarId).push({
        date: Date.now(),
        type: 'text',
        read: false,
        userId: this.currentUser.id,
        message: this.message,
        replyTo: this.replyTo
      });
    this.message = "";
    this.onCancelClicked();
    this.scrollToBottom();
  }

  getUserName(id) {
    var username;
    if (!this.userDetails.some((item) => item.id == id)) {
      this.userDetails.push({ id: id });
      this.pageService.getProfile(id).subscribe(res => {
        let updateItem = this.userDetails.find(this.findIndexToUpdate, id);
        let index = this.userDetails.indexOf(updateItem);
        this.userDetails[index] = { id: id, user_name: res.data.use_name };
        username = res.data.use_name;
      }, error => { });
    } else {
      let item = this.userDetails.find(i => i.id == id);
      username = (item != undefined) ? item.user_name : false;
    }
    return username;
  }

  findIndexToUpdate(newItem) {
    return newItem.id === this;
  }

  scrollToBottom() {
    this.contentArea.scrollToBottom(100);
  }

  async showPopover(ev: any, chat) {
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
      cssClass: 'cus-popover-one'
    });
    await popover.present();

    await popover.onDidDismiss().then(result => {
      if (result.data) {
        this.replyTo = chat.key;
        this.replyToMessage = chat.message;
        this.replyToName = this.getUserName(chat.userId);
        this.showBtmReply = true;
      }
      else
        this.replyTo = '';
    });
  }

  onCancelClicked() {
    this.replyTo = '';
    this.replyToMessage = '';
    this.replyToName = '';
    this.showBtmReply = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/common/services/alert/alert.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  isLogged: boolean = true;
  userRoles: any = [];

  constructor(public authHelperService: AuthHelperService, public alertService: AlertService) {
    this.isLogged = this.authHelperService.isLogged();
    this.userRoles = this.authHelperService.getRoles();
  }

  ngOnInit() { }

  onLogoutClicked() {
    this.alertService.logoutAlert();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './../../base.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService extends BaseService  {

  constructor(http: HttpClient) { 
    super(http);
  }

  forgotPassword(data) 
  {
    return super.post<any>('forgotpassword', true, data);
  }


}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssociationsLobbyPageRoutingModule } from './associations-lobby-routing.module';

import { AssociationsLobbyPage } from './associations-lobby.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    AssociationsLobbyPageRoutingModule
  ],
  declarations: [AssociationsLobbyPage],
  schemas: [    
    CUSTOM_ELEMENTS_SCHEMA,   
    NO_ERRORS_SCHEMA,
  ]
})
export class AssociationsLobbyPageModule {}

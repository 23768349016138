import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import { LoginTwoPage } from 'src/app/login/pages/login-two/login-two.page';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {

  tokenName: string = 'token';

  constructor(public router: Router) { }

  setToken(token: string) {
    localStorage.setItem(this.tokenName, token);
  }

  getToken() {
    return localStorage.getItem(this.tokenName);
  }

  checkToken() {
    return helper.isTokenExpired(this.getToken());
  }

  isLogged() {
    if (this.getToken() == null || this.getToken() == undefined) return false;
    else return true;
  }

  logout() {
    localStorage.removeItem(this.tokenName);

    localStorage.clear();
    this.router.navigateByUrl('/');
  }

  getName() {
    return helper.decodeToken(this.getToken()).username;
  }

  getUserId() {
    var token = helper.decodeToken(this.getToken());
    return (token ? token.sub : false);
  }

  getRoles() {
    return (this.getToken()) ? helper.decodeToken(this.getToken()).roles : false;
  }

  checkRole(role) {
    if (this.getToken() != null) {
      const _roles = helper.decodeToken(this.getToken()).roles;
      if (_roles.includes(role)) {
        return true;
      }
    }
    else {
      return false;
    }
  }
  checkPermission(permission: string) {
    if (this.getToken() != null) {
      const _permissions = helper.decodeToken(this.getToken()).permissions;
      if (_permissions.includes(permission)) {
        return true;
      }
    }
    else {
      return false;
    }
  }

}

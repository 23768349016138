import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-colorpicker',
  templateUrl: './colorpicker.page.html',
  styleUrls: ['./colorpicker.page.scss'],
})



export class ColorpickerPage implements OnInit {

  constructor(public modalCtrl: ModalController) { }
  activeColor:any;
  ngOnInit() {
  }

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  
  changeComplete(d){
    this.activeColor = d;
  }
 selectColor(){
    this.modalCtrl.dismiss(this.activeColor.color.hex); 
 }
 
}

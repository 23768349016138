import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/base.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  loginUser(data) {
    return super.post<any>('login', true, data);
  }

  loginExhibitor(data) {
    return super.post<any>('login-exhibitor', false, data);
  }

}
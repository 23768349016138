import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AdminService } from 'src/app/admin/services/admin.service';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { ResetPasswordService } from 'src/app/reset-password/services/reset-password.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.page.html',
  styleUrls: ['./user.page.scss'],
})
export class UserPage implements OnInit {
  userForm: FormGroup;
  type: any = 'create';
  isModal: boolean = false;
  userId: any;
  userData: any = [];
  isNotMatched: any = false;
  role: any;
  info: any;
  exId: any;

  constructor(public adminService: AdminService, public loadingService: LoadingService,
    public toastService: ToastService, public location: Location,
    public route: ActivatedRoute, public modalCtrl: ModalController,
    public formBuilder: FormBuilder, public alertController: AlertController,
    public resetPasswordService: ResetPasswordService) {
    this.type = this.route.snapshot.params['type'];
    this.userForm = this.formBuilder.group({
      use_name: ['', [Validators.required, Validators.minLength(3)]],
      user_email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$')]],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]{8,11}$')]],
      company: ['', [Validators.required, Validators.minLength(3)]],
      designation: ['', [Validators.required, Validators.minLength(3)]],
      country: ['', Validators.required],
      password: [{ value: '', disabled: (this.type == 'edit') ? true : false }, [Validators.required, Validators.pattern('^.{7,30}$')]],
      cpassword: [{ value: '', disabled: (this.type == 'edit') ? true : false }, Validators.required],
      role: ['', Validators.required],
      status: [{ value: '', disabled: (this.type == 'edit') ? false : true }, Validators.required],
      profilePicFid: [''],
      whNo: ['', Validators.pattern('^[0-9]{8,11}$')],
      address: [''],
      skypeId: ['']
    })

    if (this.type == 'create') {
      this.getCreateInitData();
    }
    else if (this.type == 'edit') {
      this.route.queryParams.subscribe(params => {
        this.userId = params.id;
      });
      if (this.userId == null || this.userId == undefined)
        this.location.back();
      this.getEditInitData();
    }
  }

  ngOnInit() {
    if (this.isModal) {
      this.initModalPage();
    }
  }

  getCreateInitData() {
    this.loadingService.present();
    this.adminService.createUser().subscribe(result => {
      this.loadingService.dismiss();
      this.userData = result.data;
      var sortable = [];
      for (var item in this.userData.country) {
        sortable.push({ key: item, value: this.userData.country[item] });
      }
      this.userData.country = sortable.sort((a, b) => (a.value > b.value) ? 1 : -1);
    }, error => {
      this.loadingService.dismiss();
      if ("message" in error.error) {
        this.toastService.presentToast({ message: error.error.message, status: 'error' });
      } else {
        this.toastService.presentToast({ message: JSON.stringify(error.error.error.user_email), status: 'error' });
      }
    });
  }

  getEditInitData() {
    this.loadingService.present();
    this.adminService.editUser(this.userId).subscribe(result => {
      this.loadingService.dismiss();
      this.userData = result.data;
      var sortable = [];
      for (var item in this.userData.country) {
        sortable.push({ key: item, value: this.userData.country[item] });
      }
      this.userData.country = sortable.sort((a, b) => (a.value > b.value) ? 1 : -1);
      this.updateFormData();
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  updateFormData() {
    this.userForm.patchValue({
      use_name: this.userData.user.use_name,
      user_email: this.userData.user.user_email,
      mobileNumber: this.userData.user.user_profile.mobileNumber,
      company: this.userData.user.user_profile.company,
      designation: this.userData.user.user_profile.designation,
      country: this.userData.user.user_profile.country,
      role: this.userData.user.user_profile.roId,
      status: this.userData.user.user_data.status,
      whNo: this.userData.user.user_profile.whNo,
      address: this.userData.user.user_profile.address,
      skypeId: this.userData.user.user_profile.skypeId
    });
  }

  initModalPage() {
    // var sortable = [];
    // for (var item in this.userData.country) {
    //   sortable.push({ key: item, value: this.userData.country[item] });
    // }
    // this.userData.country = sortable.sort((a, b) => (a.value > b.value) ? 1 : -1);
    this.userForm.patchValue({
      role: this.role
    });
  }

  dismiss(data) {
    this.modalCtrl.dismiss({
      'dismissed': data.status,
      'id': data.id
    });
  }

  onSubmitClicked() {
    if (this.userForm.valid) {
      this.loadingService.present();
      if (this.type == 'create' || this.isModal) {
        if (this.userForm.value.password == this.userForm.value.cpassword) {
          this.userForm.value.name = this.userForm.value.use_name;
          this.adminService.storeUser(this.userForm.value).subscribe(value => {
            this.loadingService.dismiss();
            this.toastService.presentToast({ message: value.message, status: 'success' });
            if (this.isModal) this.dismiss({ status: true, id: value.data });
            else this.location.back();
            this.userForm.reset();
          }, error => {
            this.loadingService.dismiss();
            if ("message" in error.error) {
              this.toastService.presentToast({ message: error.error.message, status: 'error' });
            } else {
              this.toastService.presentToast({ message: JSON.stringify(error.error.error.user_email), status: 'error' });
            }
          });
        } else {
          this.toastService.presentToast({ message: "Passwords doesn't match", status: 'error' });
        }
      } else if (this.type == 'edit') {
        this.userForm.value.name = this.userForm.value.use_name;
        this.adminService.updateUser(this.userForm.value, this.userId).subscribe(value => {
          this.loadingService.dismiss();
          this.toastService.presentToast({ message: value.message, status: 'success' });
        }, error => {
          this.loadingService.dismiss();
          if ("message" in error.error) {
            this.toastService.presentToast({ message: error.error.message, status: 'error' });
          } else {
            this.toastService.presentToast({ message: JSON.stringify(error.error.error.user_email), status: 'error' });
          }
        });
      }
    }
  }

  onpasswordChange() {
    if (this.userForm.value.cpassword != this.userForm.value.password) {
      this.isNotMatched = true;
    } else {
      this.isNotMatched = false;
    }
  }

  async changePassword() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Change Password',
      inputs: [
        {
          name: 'password',
          type: 'text',
          label: 'Enter Password',
          placeholder: 'Enter Password'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Ok',
          handler: (data) => {
            if (data.password && data.password.length >= 8) {
              data.useId = this.userId;
              this.loadingService.present();
              this.resetPasswordService.resetPassword(data).subscribe(value => {
                this.loadingService.dismiss();
                this.toastService.presentToast({ message: value.message, status: 'success' });
              }, error => {
                console.log(error);
                this.loadingService.dismiss();
                this.toastService.presentToast({ message: error.error.message, status: 'error' });
              });
            } else if (data.password.length < 8) {
              this.toastService.presentToast({ message: 'Passwrod length must be atleast 8 characters' });
            } else {
              this.toastService.presentToast({ message: 'Please enter password' });
            }
          }
        }
      ]
    });

    await alert.present();
  }

}
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PageService } from 'src/app/common/services/page/page.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { LobbyService } from '../../services/lobby.service';
import { environment } from 'src/environments/environment';
import { Router, NavigationExtras } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { GlobalfeedbackComponent } from 'src/app/common/components/globalfeedback/globalfeedback.component';
import { HelpdeskModalPage } from 'src/app/common/pages/helpdesk-modal/helpdesk-modal.page';
import { VideoModelPage } from 'src/app/common/pages/video-model/video-model.page';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { LoadingService } from 'src/app/common/services/loading/loading.service';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.page.html',
  styleUrls: ['./lobby.page.scss'],
})
export class LobbyPage implements OnInit {


  public static pageId: number = 8;
  pageData: any = [];
  image: any = [];
  lobbyData: any = [];
  event: any;
  logo: any;
  currentvideo: string;
  guidelineVideo: string;
  backgroundImage: string = environment.bannerDefault;

  constructor(public pageService: PageService, public toastService: ToastService,
    public lobbyService: LobbyService, public authHelperService: AuthHelperService,
    public modalController: ModalController,
    public loadingService: LoadingService,
    public navCtrl: NavController, public videoModalpage: VideoModelPage,
    public router: Router) {
    this.pageService.checkEvent();
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    var data = this.pageService.getExhibitorPreData();
    this.getInitData();
    this.getLobbyData(data);
    this.pageService.stallassets();
    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });
  }

  getLobbyData(data) {
    this.loadingService.present();
    this.lobbyService.getLobbyData(data).subscribe(value => {
      this.lobbyData = value.data;
      this.loadingService.dismiss();
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
      this.loadingService.dismiss();
    });


    this.pageService.storePageData(LobbyPage.pageId).then(value => {
      this.pageData = value;
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            if (element.image.identifier == 'bg-image') {
              this.backgroundImage = element.image.atta_url;
            }
          } else if (element.paComponentType == "video" && element.video.identifier == 'guidelines')
            this.guidelineVideo = element.video.url;
        });
      }
    });
  }

  async showModelinquiryForm() {
    const modal = await this.modalController.create({
      component: GlobalfeedbackComponent,
      cssClass: 'globalbigpopup',
      componentProps: {
        'titleData': 'inquiryForm',
      }
    });

    modal.onDidDismiss().then((value: any) => {
    });
    return await modal.present();
  }

  async onHelpdeskClicked() {
    setTimeout(function () {
      let video = document.querySelector('#lobbyvideo');
      var str = video.getAttribute("src");
      var res = str.replace("autoplay=1", "autoplay=0");
      video.setAttribute("src", res);
    }, 100);

    const modal = await this.modalController.create({
      component: VideoModelPage,
      cssClass: 'videopopup',
      componentProps: {
        'videoList': { url: this.guidelineVideo },
        'multiple': false
      }
    });

    modal.onDidDismiss().then((value: any) => {
      setTimeout(function () {
        let video = document.querySelector('#lobbyvideo');
        var str = video.getAttribute("src");
        var res = str.replace("autoplay=0", "autoplay=1");
        video.setAttribute("src", res);
      }, 100);
    });
    return await modal.present();
  }

  getInitData() {
    this.event = this.pageService.getValue('event');
    var i = 0, j = 0;
    this.pageService.storePageData(LobbyPage.pageId).then(value => {
      this.pageData = value;
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            this.image[i++] = element.image;
          }
        });
      }
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
    if (this.authHelperService.getToken() != null) this.pageService.showNotifications();
  }

  viewStall(index) {
    var exIds = this.lobbyData['exhibitor'].filter(opt => opt.exId).map(opt => opt.exId);

    let navigationExtras: NavigationExtras = {
      state: {
        exId: index
      }
    };
    this.pageService.storeValue(index, 'cur_index');
    this.pageService.storeValue(exIds, 'exIds');
    this.navCtrl.navigateForward(['stall'], navigationExtras);
  }

  ionViewWillLeave() {
    // setTimeout(function () {
    //   let video = document.querySelector('#lobbyvideo');
    //   var str = video.getAttribute("src");
    //   var res = str.replace("autoplay=1", "autoplay=0");
    //   video.setAttribute("src", res);
    // }, 100);
  }

  saveRoute(route) {
    this.pageService.saveRoute('router', route);
  }

}

import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AuthHelperService } from './common/services/auth-helper/auth-helper.service';
import { PageService } from './common/services/page/page.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  portrait: boolean = false;
  login: boolean = false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    public pageService: PageService,
    public authHelperService: AuthHelperService,
    private screenOrientation: ScreenOrientation
  ) {
    this.initializeApp();
    router.events.subscribe((val) => {    
      if (this.router.url == '/') {
        this.login = false;
       
      } else {
        this.login = true;  
      } 
    });
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {    
      if (this.router.url == '/') {
        this.login = false;
       
      } else {
        this.login = true;  
      } 
    });
  }

  menuLink(link){
    this.router.navigateByUrl(link);
  }

  // @HostListener('window:orientationchange', ['$event'])
  // onOrientationChange(event) { 
  //   console.log(event);

  //   this.portrait = false;
  //   if (window.innerHeight < window.innerWidth && this.portrait !=true) {
  //     this.portrait = true;
  //   }
  // }



  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.portrait = false;
    // if (window.innerHeight > window.innerWidth && this.portrait !=true) {
    //   this.portrait = true;
    // }   
  }

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { WelcomePage } from 'src/app/welcome/pages/welcome.page';
import { environment } from '../../../environments/environment';
import { RegistrationPage } from 'src/app/registration/pages/registration.page';
import { LoginPage } from 'src/app/login/pages/login.page';
import { ForgetPasswordPage } from 'src/app/forgot-password/pages/forget-password.page';
import { HomePage } from 'src/app/home/pages/home/home.page';
import { ModalController } from '@ionic/angular';
import { MessageModelPage } from 'src/app/common/pages/message-model/message-model.page';
import { VideoModelPage } from 'src/app/common/pages/video-model/video-model.page';


@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.page.html',
  styleUrls: ['./get-started.page.scss'],
})
export class GetStartedPage implements OnInit {


  logo: any;
  public static pageId: number = 1;
  pageData: any = [];
  backgroundImage: string = environment.bannerDefault;
  intovideo: boolean = false;
  premiumLogo: string;
  introVideo: string;

  constructor(private route: Router,
    public toastService: ToastService,
    public pageService: PageService,
    public authHelperService: AuthHelperService, public videoModalpage: VideoModelPage,
    public router: Router, public modalController: ModalController) {

  }

  playVideo() {
    this.intovideo = true;
  }

  getInitData() {
    this.pageService.storePageData(GetStartedPage.pageId).then(value => {
      this.pageData = value;
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            if (element.image.identifier == 'premiumlogo') {
              this.premiumLogo = element.image.atta_url;
            }
            if (element.image.identifier == 'bg-image') {
              this.backgroundImage = element.image.atta_url;
            }
          }
          else if (element.paComponentType == "video") {
            if (element.video.url != null) {
              this.introVideo = 'https://www.youtube.com/embed/' + this.videoModalpage.YouTubeGetID(element.video.url) + "?autoplay=1&rel=0";
            }
          }
        });
      }
    });
    this.pageService.storePageData(WelcomePage.pageId).then(value => {

      this.pageService.loadImages(value.pageData.events).then(value => {

      });
    });

    this.pageService.storePageData(RegistrationPage.pageId).then(value => {
      console.log('page ' + RegistrationPage.pageId + ' loaded');
    });
    this.pageService.storePageData(LoginPage.pageId).then(value => {
      console.log('page ' + LoginPage.pageId + ' loaded');
    });
    this.pageService.storePageData(ForgetPasswordPage.pageId).then(value => {
      console.log('page ' + ForgetPasswordPage.pageId + ' loaded');
    });
    this.pageService.storePageData(HomePage.pageId).then(value => {
      console.log('page ' + HomePage.pageId + ' loaded');
    });
  }

  ngOnInit() {
    if (this.authHelperService.getToken() && this.pageService.getValue('event')) {
      this.router.navigate(["/home"]);
    }
    this.getInitData();
    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });
    this.pageService.getEventList();
  }

  async viewFAQ() {
    const modal = await this.modalController.create({
      component: MessageModelPage,
      cssClass: 'contntpp',
      componentProps: {
        'temptype': 'bodycon',
        'exb': { pdf_atturl: '../../../assets/images/FAQ-VISITOR.pdf' },
        'titleData': 'brochure',
        'showItems': false
      }
    });

    modal.onDidDismiss().then((value: any) => {
    });
    return await modal.present();
  }
  loginPage() {
    this.router.navigateByUrl('login');
  }
  goToPage() {

    // setTimeout(function () {
    //   let video = document.querySelector('#getingst');
    //   var str = video.getAttribute("src");
    //   var res = str.replace("autoplay=1", "autoplay=0");
    //   video.setAttribute("src", res);
    // }, 100);

    this.router.navigateByUrl('home');
  }

  goToExhibitorLogin() {
    this.router.navigateByUrl('login');
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  // Stall API URL starts
  listStall(data) {
    return super.post<any>('admin/stall/get/all', true, data);
  }
  stallName() {
    return super.get<any>('exhibitors-list', true);
  }
  saveGallery(data) {
    return super.post<any>('admin/stall/gallery', true, data);
  }

  updateGallery(data, id) {
    return super.patch<any>('admin/stall/gallery/' + id, true, data);
  }

  getStall() {
    return super.get<any>('admin/stall/create', true);
  }

  saveStall(data) {
    return super.post<any>('admin/stall', true, data);
  }

  saveCompany(data) {
    return super.post<any>('create-company', true, data);
  }

  getStallData(id) {
    return super.get<any>('admin/stall/edit/' + id, true);
  }

  typeUpdateStall(data) {
    return super.post<any>('admin/stall/update/status', true, data);
  }

  updateStall(id, data) {
    return super.patch<any>('admin/stall/' + id, true, data);
  }

  deleteStallAsset(id) {
    return super.delete<any>('admin/stall/assets/' + id, true);
  }

  changePublish(id) {
    return super.get<any>('admin/exhibitor/status/' + id, true);
  }
  // Stall API URL ends

  // Menu API URL ends
  getMenus() {
    return super.get<any>('admin/menu', true);
  }
  // Menu API URL ends

  // Roles & Permissions API URL starts
  getRolePermission() {
    return super.get<any>('admin/roles-permissions', true);
  }

  updateRolePermission(data) {
    return super.patch<any>('admin/roles-permissions', true, data);
  }

  storeRole(data) {
    return super.post<any>('admin/roles', true, data);
  }

  storePermission(data) {
    return super.post<any>('admin/permissions', true, data);
  }
  // Roles & Permissions API URL ends

  // Page API URL starts
  getPageData() {
    return super.get<any>('admin/page', true);
  }

  updatePageData(data, id) {
    return super.patch<any>('admin/page/' + id, true, data);
  }
  // Page API URL ends

  // Seminar API URL starts
  getSeminarData() {
    return super.get<any>('admin/seminar/create', true);
  }

  storeSeminar(data) {
    return super.post<any>('admin/seminar', true, data);
  }

  listSeminar(id) {
    return super.get<any>('admin/seminar/get/' + id, true);
  }

  getSeminar(id) {
    return super.get<any>('admin/seminar/edit/' + id, true);
  }

  updateSeminar(data, id) {
    return super.patch<any>('admin/seminar/' + id, true, data);
  }

  deleteSeminar(id) {
    return super.delete<any>('admin/seminar/' + id, true);
  }

  changeSeminarStatus(id) {
    return super.get<any>('admin/seminar/status/' + id, true);
  }
  // Seminar API URL ends

  // Partner API URL starts
  getParnterData() {
    return super.get<any>('admin/partner/create', true);
  }

  storeParnter(data) {
    return super.post<any>('admin/partner', true, data);
  }

  listPartner(id) {
    return super.get<any>('admin/partner/get/' + id, true);
  }

  getPartner(id) {
    return super.get<any>('admin/partner/edit/' + id, true);
  }

  updatePartner(data, id) {
    return super.patch<any>('admin/partner/' + id, true, data);
  }

  deletePartner(id) {
    return super.delete<any>('admin/partner/' + id, true);
  }
  // Partner API URL ends

  // Offer API URL starts
  getOfferData(id) {
    return super.get<any>('admin/offer/create/' + id, true);
  }

  storeOffer(data) {
    return super.post<any>('admin/offer', true, data);
  }

  listOffer(id) {
    return super.get<any>('admin/offer/' + id, true);
  }

  getOffer(id) {
    return super.get<any>('admin/offer/edit/' + id, true);
  }

  updateOffer(data, id) {
    return super.patch<any>('admin/offer/' + id, true, data);
  }

  deleteOffer(id) {
    return super.delete<any>('admin/offer/' + id, true);
  }
  // Offer API URL ends

  // Stall template starts
  listStallTemplate() {
    return super.get<any>('admin/stalltemplate', true);
  }  

  getStallTemplate(id) {
    return super.get<any>('admin/stalltemplate/edit/' + id, true);
  }

  createStallTemplate(data) {
    return super.post<any>('admin/stall/templatecreate', true, data);
  }
  updateStallTemplate(data, sd) {
    return super.post<any>('admin/stall/templatecreate', true, data);
  }
  

  updateReportSettings(data) {
    return super.post<any>('admin/stall/templatecreate', true, data);
  }

 
  deleteStallTemplate(data) {
    return super.post<any>('admin/stall/templatecreate', true, data);
  }
  createStallTemplateAsset(data) {
    return super.post<any>('admin/stall/template-asset-create', true, data);
  }

  exportExhibitors(data) {
    return super.post<any>('admin/stall/template-asset-create', true, data);
  }
  importExhibitors(data) {
    return super.post<any>('admin/stall/template-asset-create', true, data);
  }
  getUserReport() {
    var data = '';
    return super.post<any>('admin/stall/template-asset-create', true, data);
  } 
  sendReport(data) {
    return super.post<any>('admin/stall/template-asset-create', true, data);
  }

  

  getStallTemplateGet(id) {
    return super.get<any>('stall-template/' + id, true);
  }

  getFile(id) {
    return super.get<any>('file/' + id, true);
  }


  getStallTemplateAsset() {
    return super.get<any>('admin/stall/template-assets', true);
  }
  // Stall template ends

  // Organizer starts
  getOrganizerdashboard() {
    return super.get<any>('admin/organizer', true);
  }
  // Organizer ends

  // Event starts
  getEventData() {
    return super.get<any>('admin/event/create', true);
  }

  storeEvent(data) {
    return super.post<any>('admin/event', true, data);
  }

  listEvent() {
    return super.get<any>('admin/event', true);
  }

  getEvent(id) {
    return super.get<any>('admin/event/edit/' + id, true);
  }

  updateEvent(data, id) {
    return super.patch<any>('admin/event/' + id, true, data);
  }

  deleteEvent(id) {
    return super.delete<any>('admin/event/' + id, true);
  }
  // Event ends

  // Users starts
  listUser() {
    return super.get<any>('admin/user', true);
  }

  createUser() {
    return super.get<any>('admin/user/create', true);
  }

  storeUser(data) {
    return super.post<any>('admin/user', true, data);
  }

  editUser(id) {
    return super.get<any>('admin/user/' + id, true);
  }

  updateUser(data, id) {
    return super.patch<any>('admin/user/' + id, true, data);
  }
  // Users ends

  // Report starts
  getUserReports() {
    return super.get<any>('admin/report/users', true);
  }
  getReports(data) {
    return super.post<any>('reports/get', true, data);
  }
  // Report Ends

  // Email Template starts
  getTemplateList() {
    return super.get<any>('admin/email', true);
  }
  storeTemplate(data) {
    return super.post<any>('admin/email', true, data);
  }
  getTemplate(id) {
    return super.get<any>('admin/email/' + id, true);
  }
  updateTemplate(data, id) {
    return super.patch<any>('admin/email/' + id, true, data);
  }
  // Email Template ends

  // Category Starts
  listCategories() {
    return super.get<any>('admin/category', true);
  }
  storeCategory(data) {
    return super.post<any>('admin/category', true, data);
  }
  getCategory(id) {
    return super.get<any>('admin/category/' + id, true);
  }
  updateCategory(data, id) {
    return super.patch<any>('admin/category/' + id, true, data);
  }
  deleteCategory(id) {
    return super.delete<any>('admin/category/' + id, true);
  }
  // Category Ends

  // Database management starts
  getVisitors() {
    return super.get<any>('admin/db/visitor', true);
  }
  deleteBoth(data) {
    return super.post<any>('admin/db/exhibitor-visitor', true, data);
  }
  deleteVisitors(data) {
    return super.post<any>('admin/db/visitor', true, data);
  }
  // Database managemant ends

  // Advertisement Starts 
  getAdSlots() {
    return super.get<any>('enhancement/home_advertisementlist', true);
  }
  submission_list() {
    return super.get<any>('enhancement/demo_list', true);
  }
  createAdSlots() {
    return super.get<any>('admin/ad-slots', true);
  }
  storeAdSlots(data) {
    return super.post<any>('admin/store-home', true, data);
  }
  editAdSlots(id) {
    return super.get<any>('homeadvertisementget/' + id, true);
  }
  
  updateAdSlots(data, id) {
    return super.post<any>('update-home/' + id, true, data);
  }
  deleteAdSlots(id) {
    return super.delete<any>('admin/ad-slots/' + id, true);
  }
  deleteAdvertisement(id) {
    return super.get<any>('home_advertisementlist/delete/' + id, true);
  } 
  // Advertisement Ends

  // Survey poll, feedback starts
  getSurvey(type, id) {
    return super.get<any>('admin/survey/' + type + '/' + id, true);
  }
  saveSurvey(data) {
    return super.post<any>('admin/survey/store', true, data);
  }
  showSurvey(id) {
    return super.get<any>('admin/survey/' + id, true);
  }
  updateSurvey(data, id) {
    return super.patch<any>('admin/survey/' + id, true, data);
  }
  deleteSurvey(id) {
    return super.delete<any>('admin/survey/' + id, true);
  }
  deleteSurveyOption(id) {
    return super.delete<any>('admin/survey/option/' + id, true);
  }
  getSeminarReport(data) {
    return super.post<any>('admin/seminar-dashboard', true, data);
  }
  // Survey poll, feedback ends

}
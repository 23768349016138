import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getEventList() {
    return super.get<any>('event/authorized', true);
  }


  getPageData(data) {
    console.log(data);
    return super.get<any>('prelogin/pageget?page='+data, true);
  }

  getMenuList(data) {
    return super.post<any>('prelogin/menu-list', false,data);
  }

  eventInsert(data) {
    return super.post<any>('event-reg', true, data);
  }

  sendExhibitorEmail(id) {
    return super.get<any>('visitor/lobby-entry/' + id, true);
  }

  premiumExhibitor() {
    return super.get<any>('prelogin/premium-exhibitor', false);
  }

  
  latestxhibitor() {
    return super.get<any>('prelogin/latest-exhibitor', false);
  }

  countryexhibitor() {
    return super.get<any>('prelogin/country-data', false);
  }

  
  get_banners(id) { 
    return super.get<any>('enhancement/home_advertisement?place='+id, false);
  }

  saveDemo(data) {
    return super.post<any>('prelogin/reques-form', true, data);
  }

}
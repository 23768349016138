import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {

  logo: any;
  public static pageId: number = 2;
  pageData: any = [];
  backgroundImage: string = environment.bannerDefault;
  logoList: any = [];
  textactive: any = 1;
  homeContent: any;
  aboutContent: any;
  contactContent: any;

  constructor(private route: Router,
    public toastService: ToastService,
    public pageService: PageService,
    public authHelperService: AuthHelperService,
    public router: Router) {
  }

  ngOnInit() {
    if (this.authHelperService.getToken() && this.pageService.getValue('event')) {
      // this.router.navigate(["/home"]);
      this.router.navigate(["/lobby"]);
    }
    this.getInitData();
    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });

  }

  getInitData() {
    this.pageService.storePageData(WelcomePage.pageId).then(value => {
      this.pageData = value;
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            if (element.image.identifier == 'bg-image') {
              this.backgroundImage = element.image.atta_url;
            }
          }
        });
      }
      this.pageData.page_body.forEach(ele => {
        if (ele.section == "home")
          this.homeContent = ele.content;
        else if (ele.section == "about_us")
          this.aboutContent = ele.content;
        else if (ele.section == "contact_us")
          this.contactContent = ele.content;
      });
    });
  }

}
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OtpService } from '../../services/otp.service';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { MessageModelPage } from 'src/app/common/pages/message-model/message-model.page';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.page.html',
  styleUrls: ['./otp.page.scss'],
})
export class OtpPage implements OnInit {

  @Input() messageData: string;
  @Input() userId: any;
  @Input() userEmail: any;
  otpForm: FormGroup;
  emailForm: FormGroup;
  editClicked: boolean = false;

  constructor(private formBuilder: FormBuilder, public loadingService: LoadingService, public modalController: ModalController,
    public otpService: OtpService, public route: ActivatedRoute, public toastService: ToastService) {
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]],
    });

    this.emailForm = this.formBuilder.group({
      user_email: ['', Validators.required]
    });
  }

  ngOnInit() {

  }


  onProceedClicked() {
    this.loadingService.present();
    var OTP = this.otpForm.value.otp1 + this.otpForm.value.otp2 + this.otpForm.value.otp3 + this.otpForm.value.otp4;
    this.otpService.verifyOTP({ 'useId': this.userId, 'code': OTP }).subscribe(value => {
      this.loadingService.dismiss();
      this.modalController.dismiss({
        'value': value
      });
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  onResendOTPClicked() {
    this.loadingService.present();
    this.otpService.resendOTP({ 'id': this.userId }).subscribe(value => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: value.message, status: 'success' });
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  onEditClicked() {
    this.editClicked = true;
  }

  onSaveClicked() {
    this.emailForm.value.id = this.userId;
    this.loadingService.present();
    this.otpService.changeEmail(this.emailForm.value).subscribe(value => {
      this.editClicked = false;
      this.userEmail = this.emailForm.value.user_email.substring(0, 4)
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: value.message, status: 'success' });
    }, error => {
      this.loadingService.dismiss();
      if ("message" in error.error) {
        this.toastService.presentToast({ message: error.error.message, status: 'error' });
      } else {
        this.toastService.presentToast({ message: JSON.stringify(error.error.error.user_email), status: 'error' });
      }
    });
  }

  onCancelClicked() {
    this.editClicked = false;
  }

  changeFoucus(event, next, prev) {
    if (event.target.value.length < 1 && prev) {
      prev.setFocus()
    }
    else if (next && event.target.value.length > 0) {
      next.setFocus();
    }
    else {
      return 0;
    }
  }
}
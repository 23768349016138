import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FilePage } from 'src/app/file/pages/file.page';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { OtpPage } from 'src/app/otp/pages/otp/otp.page';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { MessageModelPage } from 'src/app/common/pages/message-model/message-model.page';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { environment } from 'src/environments/environment';
import { HomePage } from 'src/app/home/pages/home/home.page';
import { LoginService } from '../../services/login.service';
import { LoginPage } from '../login.page';

@Component({
  selector: 'app-login-two',
  templateUrl: './login-two.page.html',
  styleUrls: ['./login-two.page.scss'],
})
export class LoginTwoPage implements OnInit {
  loginForm: FormGroup;
  remember: any;
  public static pageId: number = 3;
  pageData: any = [];
  logo: any;

  backgroundImage: string = environment.bannerDefault;

  constructor(private formBuilder: FormBuilder,
    public loadingService: LoadingService, public modalController: ModalController,
    public router: Router, public toastService: ToastService, public loginService: LoginService,
    public authHelperService: AuthHelperService, public pageService: PageService) {

    this.loginForm = this.formBuilder.group({
      user_email: ['', [Validators.required, Validators.email]],
      // password: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.pageService.getEventList();
    this.getInitData();

    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });
  }

  getInitData() {
    this.pageService.storePageData(LoginPage.pageId).then(value => {
      this.pageData = value;
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            if (element.image.identifier == 'bg-image') {
              this.backgroundImage = element.image.atta_url;
            }
          }
        });
      }
    });

    this.pageService.storePageData(HomePage.pageId).then(value => {
      console.log('page ' + HomePage.pageId + ' loaded');
    });
  }


  async showModalotp(tokenData) {
    const modal = await this.modalController.create({
      component: MessageModelPage,
      cssClass: 'msgpop',
      backdropDismiss: false,
      componentProps: {
        'titleData': 'Successfully verified',
        'msgtype': 'sucess',
        'temptype': 'social',
        'messageData': "Invite your friends by sharing in social media"
      }
    });

    modal.onDidDismiss().then((data: any) => {
      this.setToken(tokenData);
    });

    return await modal.present();
  }


  onLoginClicked() {
    this.loadingService.present();
    this.loginService.loginUser(this.loginForm.value).subscribe(value => {
      this.loadingService.dismiss();
      if (value.status) {
        this.setToken(value);
      } else {
        if (!value.userData.user_data.OTPverified) {//user not verified
          this.dismiss();
          this.showModal(value.userData);
        }
        // if (!value.userData.user_data.OTPverified && !value.userData.user_data.OTPsent &&
        //   value.userData.user_data.status != 'blocked') {//user not verified
        //   this.setToken(value);
        // } else if (value.userData.user_data.OTPsent && !value.userData.user_data.OTPverified
        //   && value.userData.user_data.status == 'blocked') {//OTP sent but not verified
        //   this.dismiss();
        //   this.showModal(value.userData);
        // }
        else {//User blocked       
          this.toastService.presentToast({ message: value.message, status: 'error' });
          this.dismiss();
        }
      }
      this.loginForm.reset();
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error, status: 'error' });
      this.modalController.dismiss({ status: false, error: error, user_email: this.loginForm.value.user_email });
    });
  }

  setToken(value) {
    this.authHelperService.setToken(value.token.access_token);
    this.storeUser(value.userData);
    this.toastService.presentToast({ message: 'Login Successful', status: 'success' });
    this.modalController.dismiss({ status: true });
    this.pageService.redirect();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  storeUser(data) {
    this.pageService.storeValue(data, 'userInfo');
  }

  async showModal(data) {
    const modal = await this.modalController.create({
      component: OtpPage,
      cssClass: 'msgpop bigspo wd',
      backdropDismiss: false,
      componentProps: {
        'messageData': 'Your pre-registered login ID is not registered as visitor for this current show. Please proceed here to register!',
        'userId': data.id,
        'userEmail': data.user_email.substring(0, 4)
      }
    });

    modal.onDidDismiss().then((data: any) => {
      this.showModalotp(data.data.value);
    });

    return await modal.present();
  }

  async onUploadFilesClicked() {
    const modal = await this.modalController.create({
      component: FilePage,
    });
    modal.onDidDismiss().then((data: any) => {
    });
    return await modal.present();
  }

  goToUrl(url) {
    this.modalController.dismiss();
    this.router.navigateByUrl(url);
  }

}

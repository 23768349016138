import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CountriesLobbyPageRoutingModule } from './countries-lobby-routing.module';

import { CountriesLobbyPage } from './countries-lobby.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    CountriesLobbyPageRoutingModule
  ],
  schemas: [    
    CUSTOM_ELEMENTS_SCHEMA,   
    NO_ERRORS_SCHEMA,
  ],
  declarations: [CountriesLobbyPage]
})
export class CountriesLobbyPageModule {}

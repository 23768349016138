import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, UrlSegment, Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { Route } from '@angular/compiler/src/core';
import { AuthHelperService } from './common/services/auth-helper/auth-helper.service';
import { ToastService } from './common/services/toast/toast.service';
import { Location } from '@angular/common';
import { stat } from 'fs';
import { ModalController } from '@ionic/angular';
import { LoginTwoPage } from './login/pages/login-two/login-two.page';
import { PageService } from './common/services/page/page.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private _authHelperService: AuthHelperService,
    public toastService: ToastService,
    private route: ActivatedRoute,
    private location: Location, public modalController: ModalController
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authHelperService.getToken() != null) {
      if (!this._authHelperService.checkToken()) {
        if (next.data.access == undefined || this._authHelperService.checkPermission(next.data.access)) {
          return true;
        } else {
          this.pageRedirect(state);
          return false;
        }
      }
      else {
        this.toastService.presentToast({ message: "Your session has been expired. Please login again", status: 'error' });
        localStorage.clear();
        this.router.navigate(["/login"]);
        return false;
      }
    } else {
      this.goToLogin();
      // this.pageRedirect(state);
      // return false;
    }
  }

  async goToLogin() {
    const modal = await this.modalController.create({
      component: LoginTwoPage,
      cssClass: 'popup3'
    });
    modal.onDidDismiss().then((data: any) => {
      if (data.data) {
        if (!data.data.status) {
          let navigationExtras: NavigationExtras = {
            state: {
              user_email: data.data.user_email
            }
          };
          // this.router.navigate(["/register"], navigationExtras);
        }
      }
    });
    return await modal.present();
  }

  pageRedirect(state) {
    this.router.navigate(['/access-denied'], { skipLocationChange: true, queryParams: { url: state.url } });
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-event-category',
  templateUrl: './event-category.component.html',
  styleUrls: ['./event-category.component.scss'],
})
export class EventCategoryComponent implements OnInit {

  @Input() eventCategories: any = [];
  choosedIndex: any = [];
  isModal: boolean = false;
  constructor(public modalCtrl: ModalController) { }

  ngOnInit() { }

  onCheckBoxChanged(level?: number) {
    this.choosedIndex = [];
    this.choosedIndex = this.getSelectedIndex();
  }

  getSelectedIndex() {
    var index = [], names = [];
    this.eventCategories.forEach(cat => {
      for (let i = 0; i < cat.categories.length; i++) {
        var category = cat.categories[i].child;
        for (let l = 0; l < category.length; l++) {
          var child = category[l].child;
          if (category[l].isChecked) {
            index.push(category[l].caiId);
            names.push(category[l].catItemName);
          }
          for (let j = 0; j < child.length; j++) {
            var children = child[j].child;
            if (child[j].isChecked) {
              index.push(child[j].caiId);
              names.push(child[j].catItemName);
            }
            for (let k = 0; k < children.length; k++) {
              if (children[k].isChecked) {
                index.push(children[k].caiId);
                names.push(children[k].catItemName);
              }
            }
          }
        }
      }
    });
    return [index, names];
  }

  dismiss(status) {
    this.modalCtrl.dismiss({
      'dismissed': status,
      'categories': this.choosedIndex
    });
  }

  checkChoosedCategories(choosedItems, eveCategories) {
    var names = [];
    eveCategories.forEach(cat => {
      for (let i = 0; i < cat.categories.length; i++) {
        var element = cat.categories[i];
        var child = element.child;
        element.isChecked = choosedItems.includes(element.caiId) ? true : false;
        if (element.isChecked) names.push(element.catItemName);
        for (let j = 0; j < child.length; j++) {
          var children = child[j].child;
          child[j].isChecked = choosedItems.includes(child[j].caiId) ? true : false;
          if (child[j].isChecked) names.push(child[j].catItemName);
          for (let k = 0; k < children.length; k++) {
            var level4 = children[k].child;
            children[k].isChecked = choosedItems.includes(children[k].caiId) ? true : false;
            if (children[k].isChecked) names.push(children[k].catItemName);
            for (let l = 0; l < level4.length; l++) {
              level4[l].isChecked = choosedItems.includes(level4[l].caiId) ? true : false;
              if (level4[l].isChecked) names.push(level4[l].catItemName);
            }
          }
        }
      }
    });
    return [eveCategories, names];
  }

}

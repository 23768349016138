import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { AlertController, ModalController } from '@ionic/angular';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { AlertService } from 'src/app/common/services/alert/alert.service';
import { FilePage } from 'src/app/file/pages/file.page';
import { PageService } from 'src/app/common/services/page/page.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.page.html',
  styleUrls: ['./user.page.scss'],
})
export class UserPage implements OnInit {

  userId: number;
  user: any = [];
  public static pageId: number = 12;
  pageData: any = [];
  briefCase: any= [];
  userRoles: any = [];

  constructor(public userService: UserService, public authHelperService: AuthHelperService,
              public toastService: ToastService, public alertController: AlertController,
              public loadingService: LoadingService, public alertService: AlertService,
              public modalController: ModalController, public pageService: PageService) 
  { 
    this.userId = this.authHelperService.getUserId();
    this.userRoles = this.authHelperService.getRoles();
  }

  ngOnInit() {
    this.getUserProfile();
    this.getInitData();
    this.getBriefCase();
  }

  getInitData()
  {
    this.pageService.storePageData(UserPage.pageId).then(value => {
      this.pageData = value;
    });
  }

  getUserProfile()
  {
    this.pageService.getUserProfile().then(value => {
      this.user = value;
    });
  }

  onLogoutClicked()
  {
    this.alertService.logoutAlert();
  }

  logout()
  {
    this.authHelperService.logout();
  }

  async onEditPictureClicked()
  {
    const modal = await this.modalController.create({
      component: FilePage,
      cssClass: 'file-popup',
      componentProps: {
        'chooseFile' : false,
        'postLogin' : true
      }
    });

    modal.onDidDismiss().then((data) => {
      if(data.data != undefined)
      {
        var myFormData = new FormData();
        myFormData.append('attachment', data.data.file);
        myFormData.append('useId', JSON.stringify(this.user.id));
        this.loadingService.present();
        this.userService.updateProfilePic(myFormData).subscribe(value => {
          this.pageService.storeValue(value.data, 'userInfo');
          this.loadingService.dismiss();
          this.user = value.data;  
          this.toastService.presentToast({ message: value.message, status: 'success' });
        }, error => {
          this.loadingService.dismiss();
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        });
      }
    });

    return await modal.present();
  }

  onDownloadDataClicked()
  {
    this.loadingService.present();
    this.userService.downloadData().subscribe(value => {
      this.loadingService.dismiss();
      const blob = new Blob([value], {type: 'application/zip'});
      var downloadURL = window.URL.createObjectURL(value);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "files.zip";
      link.click();
      this.toastService.presentToast({ message: 'Files downloaded', status: 'success' });
    }, error => {
      console.log(error);
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }
 
  getBriefCase()
  {
    this.userService.getMyBrief(this.userId).subscribe(value => { 
      this.briefCase = value.data;
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });  
    }); 
  }

}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $: any
@Component({
  selector: 'app-exhibitor-event-category',
  templateUrl: './exhibitor-event-category.component.html',
  styleUrls: ['./exhibitor-event-category.component.scss'],
})
export class ExhibitorEventCategoryComponent implements OnInit {

  @Input() categories: any = [];
  @Input() type: string;
  @Input() exhibitorsCount: number;
  @Output() onCheckBoxChanged: EventEmitter<any> = new EventEmitter();
  showl1: boolean = false;
  showl2: boolean = false;
  showl3: boolean = false;
  level1: any = [];
  level2: any = [];
  level3: any = [];

  constructor() { }

  ngOnInit() {
    if (this.type != 'womms') {
      this.categories.forEach(element => {
        for (let j = 0; j < element.child.length; j++) {
          element.count += element.child[j].count;
        }
        if (element.count > 0 && element.count > this.exhibitorsCount) element.count = this.exhibitorsCount;
      });
    }
    $('.bakbt').click(function(){
        console.log('sf');
    });
  }

  viewMystalllist(){
    this.categories[0].isChecked = true;
    this.updateChildCategories(this.categories[0]);
  }

  oncheckBoxChanged(level: number, choosedValue?: any) {

    // console.log(level);
    // console.log(choosedValue);
    if (!choosedValue.isChecked) this.updateChildCategories(choosedValue.child);

    if (level == 0) {
      if (choosedValue.isChecked) {
        this.showl1 = true;
        this.level1 = choosedValue.child;
      }
      else {
        let choosed = this.categories.find(e => e.isChecked == true);
        if (!choosed || choosed.child.length <= 0) {
          this.showl1 = false;
          this.level1 = [];
        } else if (choosed.child.length > 0) {
          this.showl2 = false;
          this.showl3 = false;
        }
      }
    }
    else if (level == 1) {
      if (choosedValue.isChecked) {
        this.showl2 = true;
        this.level2 = choosedValue.child;
      }
      else {
        let choosed = this.level1.find(e => e.isChecked == true);
        if (!choosed) {
          this.showl2 = false;
        }
        this.showl3 = false;
      }
    }
    else if (level == 2) {
      if (choosedValue.isChecked) {
        this.showl3 = true;
        this.level3 = choosedValue.child;
      }
      else {
        let choosed = this.level2.find(e => e.isChecked == true);
        if (!choosed) {
          this.showl3 = false;
        }
      }
    }
    this.onCheckBoxChanged.emit(level);
  }

  onLabelClicked(level: number, choosedValue?: any) {


    
    choosedValue.isChecked = true;
    if (level == 0) {
      this.showl1 = true;
      this.level1 = choosedValue.child;
      this.showl2 = false;
      this.showl3 = false;
    }
    else if (level == 1) {
      this.showl2 = true;
      this.level2 = choosedValue.child;
      this.showl3 = false;
    }
    else if (level == 2) {
      this.showl3 = true;
      this.level3 = choosedValue.child;
    }
  }

  updateChildCategories(category) {
    for (let i = 0; i < category.length; i++) {
      var children = category[i].child;
      category[i].isChecked = false;
      if (children.length > 0)
        this.updateChildCategories(children);
    }
  }

  getChildCategory(level) {
    if (level == 1)
      return this.level1;
    else if (level == 2)
      return this.level2;
    else if (level == 3)
      return this.level3;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { HomeService } from 'src/app/home/services/home.service';
import { ToastService } from '../../services/toast/toast.service';
import { AuthHelperService } from '../../services/auth-helper/auth-helper.service';
import { LoadingService } from '../../services/loading/loading.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {

  @Input() eventId: number;
  @Input() eventImg: any;
  @Input() category: any = [];
  match: boolean = false;
  userId: number;

  constructor(public homeService: HomeService,
    private modalCtrl: ModalController,
    public toastService: ToastService, public loadingService: LoadingService, public authHelperService: AuthHelperService) {
    this.userId = this.authHelperService.getUserId();
  }

  ngOnInit() {
  }

  onSelect() {
    let selected = this.category.filter(opt => opt.cheked).map(opt => opt.caiId);
    if (selected.length > 0) {
      this.loadingService.present();

      let data = {
        "evId": this.eventId,
        "evRegMatchmaking": this.match,
        "eventRegCat": selected,
        "userId": this.userId,
      };

      this.homeService.eventInsert(data).subscribe(value => {
        this.loadingService.dismiss();
        this.modalCtrl.dismiss({
          'dismissed': true,
          'reg': true
        });
      }, error => {
        this.loadingService.dismiss();
        console.log(error);
        this.modalCtrl.dismiss({
          'dismissed': true,
          'reg': true
        });
        // this.toastService.presentToast({ message: error.error.message, status: 'error' });
      });
    } else {
      this.toastService.presentToast({ message: 'Choose atleast one category', status: 'error' });
    }
  }

}



import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';
import { PageService } from 'src/app/common/services/page/page.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { EventService } from './event.service';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/base.service';

//import { Events } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ChatService  extends BaseService{

  buddymessages = [];
  boddyUser
  Conversations = [];
  user = [];


  public message: any;

  public messages: any;

  userId;
  image;
  keyMessage;

  constructor(
    public agAuth: AngularFireAuth,
    // public events: Events,
    public events: EventService,
    public pageService: PageService,
    http: HttpClient,
    public afDB: AngularFireDatabase,
    public authHelperService: AuthHelperService

  ) {

    //  
    super(http);
    this.getUserProfile();


  } 

  getUserProfile() {

  }
  
  setConversations(uid, message)  {
    let messages1 = {
      date: new Date().toString(), 
      message:message,
      read: 'unread',
      type: 'text',
     userId: this.authHelperService.getUserId(),

    }

    firebase.database().ref('networkingconversations').child(this.authHelperService.getUserId()).child(uid).set(messages1);
    firebase.database().ref('networkingconversations').child(uid).child(this.authHelperService.getUserId()).set(messages1);

  }


  setStallConversations(uid, message, type)  {
    let messages1 = {
      date: new Date().toString(), 
      message:message,
      read: 'unread',
      type: type,
     userId: this.authHelperService.getUserId(),

    }

    firebase.database().ref('stallconversations').child(this.authHelperService.getUserId()).child(uid).set(messages1);
    firebase.database().ref('stallconversations').child(uid).child(this.authHelperService.getUserId()).set(messages1);

  }


 
  messageChat(uid, message) {


    let messages1 = {
      date: new Date().toString(),
     // status: "Online",
      message:message,
     userId: this.authHelperService.getUserId()
    }

    firebase.database().ref('networingchat').child(this.authHelperService.getUserId()).child(uid).push(messages1).then((sucess) => {
      this.keyMessage = sucess.key;
     // console.log(this.keyMessage);
       //messages1.userId = uid;
      firebase.database().ref('networingchat').child(uid).child(this.authHelperService.getUserId()).push(messages1).then((sucess) => {
        this.keyMessage = sucess.key;
       /// console.log(this.keyMessage);
      }).then(() => {
        //console.log('then');
      });

    }).then(() => {
     /// console.log('then');
    });
   
    this.setConversations(uid, message);
  }




  messageStallChat(uid, message, type) {


    let messages1 = {
      date: new Date().toString(),
     // status: "Online",
      type: type,
      message:message,
     userId: this.authHelperService.getUserId()
    }

    firebase.database().ref('stallchat').child(this.authHelperService.getUserId()).child(uid).push(messages1).then((sucess) => {
      this.keyMessage = sucess.key;
      firebase.database().ref('stallchat').child(this.authHelperService.getUserId()).child(uid).child(this.keyMessage).update({'key': this.keyMessage});
     // console.log(this.keyMessage);
       //messages1.userId = uid;
      firebase.database().ref('stallchat').child(uid).child(this.authHelperService.getUserId()).push(messages1).then((sucess) => {
        this.keyMessage = sucess.key;
      firebase.database().ref('stallchat').child(uid).child(this.authHelperService.getUserId()).child(this.keyMessage).update({'key': this.keyMessage})
       /// console.log(this.keyMessage);
      }).then(() => {
        //console.log('then');
      });

    }).then(() => {
     /// console.log('then');
    });
   
    this.setStallConversations(uid, message, type);
  }


  getMyConnentions()
  {
    return super.get<any>('my-connection', true);
  }

  getuserProfile(id)
  {
    return super.get<any>('getuser/'+id, true);
  }

  translateText(data)
  {
    return super.post<any>('translate', true, data);
  }
  

  groupsChat(userId) {
    let res;
    this.afDB.database.ref('/messages').child(firebase.auth().currentUser.uid).child(userId).on('value', (snapshot) => {
      this.buddymessages = []
      res = snapshot.val()
      for (var i in res) {
        this.buddymessages.push(res[i])
      }
       this.events.publish('messages')

    })
  }

  getConversations() {
    this.afDB.database.ref('/conversations/').child(firebase.auth().currentUser.uid).on('value', snap => {
      this.Conversations = []
      var array1 = []
      var res = snap.val()
      for (var i in res) {
        this.Conversations.push(res[i])
        array1.push(res[i].userId)
      }
      this.afDB.database.ref('/accounts/').on('value', snap => {
        this.boddyUser = []
        var res = snap.val();
        var array = []
        for (var i in res) {
          array.push(res[i])
        }
        for (var d in array1.reverse()) {
          for (var c in array.reverse()) {
            if (array[c].userId === array1[d]) {
              this.boddyUser.push(array[c])
            }
          }
        }
        this.events.publish('conversations');
      })

    })
  }

  // Get group Conversation
  GetGroupConversation() {

  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ColorpickerPageRoutingModule } from './colorpicker-routing.module';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorpickerPage } from './colorpicker.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ColorSketchModule,
    ColorpickerPageRoutingModule
  ],
  declarations: [ColorpickerPage]
})
export class ColorpickerPageModule {}

import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LobbyService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getLobbyData(data) {
    return super.post<any>('lobby', true, data);
  }

  getCategoryItem(id) {
    return super.get<any>('category-item/' + id, true);
  }

}
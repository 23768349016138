import { Component, OnInit, ViewChild } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from 'src/app/admin/services/admin.service';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { AdminMenuComponent } from 'src/app/components/admin-menu/admin-menu.component';
import { NavController } from '@ionic/angular';
import { ExportService } from 'src/app/common/services/export/export.service';
import { map } from 'jquery';

@Component({
  selector: 'app-user-registered',
  templateUrl: './user-registered.page.html',
  styleUrls: ['./user-registered.page.scss'],
})
export class UserRegisteredPage implements OnInit {

  dateType: any = 'all';
  stdate: any;
  eddate: any;
  exhibitors: any;
  stallForm: FormGroup;
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  downloaddata: any = [];
  endMinDate: any;
  showEndate: boolean = false;
  allUsers: any = [];
  filteresUsers: any = [];
  showDelete: boolean = false;
  showEdit: boolean = false;
  currole: any = 'all';
  tableColumns: Array<string> = [];

  constructor(public adminService: AdminService, public toastService: ToastService,
    public navCtrl: NavController, public loadingService: LoadingService,
    public exportService: ExportService, public route: ActivatedRoute,
    public pageService: PageService) {

    this.tableColumns = ['No.', 'Name', 'Email', 'Designation', 'Company', 'MobileNumber', 'Country', 'Role', 'RegisteredDate'];
  }

  ngOnInit() {
    this.getUserList();
  }

  onDateChanged(type, date) {
    if (type == 'startDate') {
      this.endMinDate = formatDate(new Date((new Date(date.value)).getTime() + (60 * 60 * 24 * 1000)), 'yyyy-MM-dd', 'en');
      this.showEndate = true;
      this.stdate = formatDate(new Date((new Date(date.value)).getTime()), 'yyyy-MM-dd', 'en');
      this.eddate = this.endMinDate;
    }
    if (type == 'customdate') {
      this.stdate = formatDate(new Date((new Date(date.value)).getTime()), 'yyyy-MM-dd', 'en');
    }
    if (type == 'endDate') {
      this.eddate = formatDate(new Date((new Date(date.value)).getTime()), 'yyyy-MM-dd', 'en');
    }
  }

  getUserList() {
    this.loadingService.present();
    this.adminService.getUserReports().subscribe(result => {
      this.loadingService.dismiss();
      this.filteresUsers = [];
      result.data.forEach((element, index) => {
        this.filteresUsers.push({ 'No.': ++index, 'Name': element.FirstName, 'Email': element.Email, 'Designation': element.Designation, 'Company': element.Company, 'MobileNumber': element.CellPhoneNumber, 'Country': element.Code, 'Role': element.Role, 'RegisteredDate': element.Date });
        this.allUsers.push({ 'No.': ++index, 'Name': element.FirstName, 'Email': element.Email, 'Designation': element.Designation, 'Company': element.Company, 'MobileNumber': element.CellPhoneNumber, 'Country': element.Code, 'Role': element.Role, 'RegisteredDate': element.Date });
      });
      this.filteresUsers.count = this.filteresUsers.length;
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }


  onChangeType(data) {
    this.dateType = data.value;
    if (data.value == 'today') {
      this.stdate = formatDate(new Date((new Date()).getTime()), 'yyyy-MM-dd', 'en');
    }
    if (data.value == 'yesterday') {
      this.stdate = formatDate(new Date((new Date()).getTime() - (60 * 60 * 24 * 1000)), 'yyyy-MM-dd', 'en');
    }
  }

  onRoleChange(data) {
    this.currole = data.value;
    //this.filteresUsers = this.allUsers.filter(pilot => pilot.Role == data.value);  
  }




  filterUser() {
    if (this.dateType == 'today' || this.dateType == 'yesterday' || this.dateType == 'custom') {
      if (this.currole == 'all') {
        this.filteresUsers = this.allUsers.filter((user) => (formatDate(new Date((new Date(user.RegisteredDate)).getTime()), 'yyyy-MM-dd', 'en') == this.stdate)).map(user => user);
      } else {
        this.filteresUsers = this.allUsers.filter((user) => (formatDate(new Date((new Date(user.RegisteredDate)).getTime()), 'yyyy-MM-dd', 'en') == this.stdate) && (user.Role == this.currole)).map(user => user);
      }
    } else if (this.dateType == 'customrange') {
      if (this.currole == 'all') {
        this.filteresUsers = this.allUsers.filter((user) => (formatDate(new Date((new Date(user.RegisteredDate)).getTime()), 'yyyy-MM-dd', 'en') >= this.stdate) && (formatDate(new Date((new Date(user.RegisteredDate)).getTime()), 'yyyy-MM-dd', 'en') <= this.eddate)).map(user => user);
      } else {
        this.filteresUsers = this.allUsers.filter((user) => (formatDate(new Date((new Date(user.RegisteredDate)).getTime()), 'yyyy-MM-dd', 'en') >= this.stdate) && (formatDate(new Date((new Date(user.RegisteredDate)).getTime()), 'yyyy-MM-dd', 'en') <= this.eddate && user.Role == this.currole)).map(user => user);
      }
    } else {
      if (this.currole != 'all') {
        this.filteresUsers = this.allUsers.filter((user) => user.Role == this.currole).map(user => user);
      } else {
        this.filteresUsers = this.allUsers;
      }
    }
  }

  export(name) {
    this.exportService.exportExcel(this.filteresUsers, name);
  }
}

import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { NavController } from '@ionic/angular';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { AssociationService } from '../../services/association.service';
import { HomeService } from 'src/app/home/services/home.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-associations-lobby',
  templateUrl: './associations-lobby.page.html',
  styleUrls: ['./associations-lobby.page.scss'],
})
export class AssociationsLobbyPage implements OnInit {

  assoData: any;
  eventList: any = [];
  constructor(public associationService: AssociationService,
    public loadingService: LoadingService,
    public navCtrl: NavController,
    public pageService: PageService,
    public homeService: HomeService,
    public router: Router,
    public toastService: ToastService,) { }


  ngOnInit() {
    this.assoData = this.pageService.getValue('association-category');
  }
  ionViewWillEnter() {
    this.getEventList();
  }

  getEventList() {
    this.homeService.getEventList().subscribe(value => {
      this.eventList = value.data;
      if (this.eventList.length <= 0) {
        this.router.navigate(['/info-template'], { skipLocationChange: true });
      } else {
        this.eventList = value.data[0];
        this.pageService.storeValue({ id: this.eventList.eveId, logo: this.eventList.file.atta_url, name: this.eventList.eveName }, 'event');
      }
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }


  onCategoryClicked(type) {
    if ('event_category' in this.eventList) {
      var category = this.eventList.event_category.find((ele) => ele.category_item.catItemSlug == type);
      if (category) {
        this.pageService.storeValue({ id: category.caiId, name: category.category_item.catItemName }, 'cat_level2');
        this.router.navigate(['lobby']);
      }
    }
  }

}

import { Component, OnInit, Pipe, Input, ElementRef, PipeTransform, Renderer2 } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';
import { StallService } from 'src/app/stall/services/stall.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PageService } from '../../services/page/page.service';
import { StallPage } from 'src/app/stall/pages/stall/stall.page';
import { EventService } from 'src/app/chat/service/event.service';
import { AuthHelperService } from '../../services/auth-helper/auth-helper.service';


@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@Component({
  selector: 'app-message-model',
  templateUrl: './message-model.page.html',
  styleUrls: ['./message-model.page.scss'],
})
export class MessageModelPage implements OnInit {

  activeItem: any;

  constructor(
    private modalCtrl: ModalController, public events: EventService,
    public route: Router, public pageService: PageService,
    public stallService: StallService, public authHelperservice: AuthHelperService,
    public toastService: ToastService) { }

  @Input() titleData: string;
  @Input() messageData: any = [];
  @Input() msgtype: string;
  @Input() user: any;
  @Input() showItems: boolean = true;
  @Input() multiple: boolean = false;
  @Input() data: any = [];
  @Input() exb: any = [];
  @Input() type: any;
  @Input() image: any;
  @Input() temptype: string;

  ngOnInit() {
    if (this.multiple) {
      this.activeItem = this.data[0];
      this.setActive(0);
    }
    else if (this.type == 'banner' || this.type == 'standee') {
      this.visit(this.image.fasFid);
    }
  }

  addVisit() {
    if (this.type == 'brochure') {
      this.visit(this.activeItem.fId);
    }
    else if (this.type == 'visitingcard') {
      this.visit(this.activeItem.fId);
    }
  }

  visit(assetId) {
    var params = { 'useId': this.authHelperservice.getUserId(), 'pageType': this.type, 'pageTypeId': this.exb.stId, 'assId': assetId };
    this.stallService.saveVisit(params).subscribe(value => {
    }, error => {
      console.log(error);
    });
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  enternowdismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }


  addBrief(ext, fId) {
    this.events.subscribe('add-brief', async () => {
      var d = { useId: this.authHelperservice.getUserId(), fId: fId, exId: ext.exId };
      this.stallService.brifStore(d).subscribe(value => {
        this.toastService.presentToast({ message: 'Saved to briefcase', status: 'success' });
      }, error => {
        this.toastService.presentToast({ message: error.error.message, status: 'error' });
      });
    });

    if (this.authHelperservice.isLogged()) this.pageService.publishEvent('add-brief');
    else {
      this.dismiss();
      this.pageService.saveRoute('action', 'add-brief');
      this.pageService.goTologin();
    }
  }

  onPrintClicked(url) {
    this.pageService.printAttachment(url);
  }

  onEmailClicked(id) {
    this.events.subscribe('send-attachment', async () => {
      this.pageService.sendAttachmentEmail(id);
    });
    if (this.authHelperservice.isLogged()) this.pageService.publishEvent('send-attachment');
    else {
      this.dismiss();
      this.pageService.saveRoute('action', 'send-attachment');
      this.pageService.goTologin();
    }
  }

  setActive(index) {
    this.activeItem = '';
    this.activeItem = this.data[index];
    this.activeItem.furl = this.activeItem.furl;
    this.data.forEach(element => {
      element.active = false;
    });
    this.data[index].active = true;
    this.addVisit();
  }
}

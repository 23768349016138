import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAllCountries(id) {
    return super.get<any>('countries/' + id, true);
  }

}